/* App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #332b2b1d
}

header {
  display: flex;
  flex-direction: column; /* Empilha os elementos verticalmente */
  align-items: center; /* Centraliza os itens horizontalmente */
  justify-content: flex-start;
  padding: 20px;
  color: #fff; /* Texto branco */
  position: relative; /* Para posicionar elementos filhos */
  background: linear-gradient(-12deg,#FFCB04 0%, #FFCB04 33%, #EE302F 33%, #EE302F 66%, #00AB4E
  66%, #00AB4E 100%);
}

/* Estilo para o logo */
header img {
  width: 300px; /* Fixme */
  height: auto;
}


.menu {
  display:  flex;  /* Coloca os elementos-filhos um ao lado do outro */
  margin: 0 auto;
}

.menu ul {font-size: 15px; text-align:center; }
.menu ul li {display: inline; }
.menu ul li a {margin: 0 auto;}

nav {
  border: 2px solid #fff; /* Adiciona uma borda sólida branca ao redor do menu */
  background-color: rgba(213, 213, 213, 0.8); /* Cor de fundo com transparência */
  border-radius: 10px; /* Adiciona bordas arredondadas ao redor do menu */
}

nav ul {
  padding-left: 0;
}

nav ul li {
  display: inline;
}

nav ul li a {
  color: #070707;
  text-decoration: none;
  padding: 10px 15px; /* Adiciona padding para criar as caixas */
  border: 2px solid transparent; /* Adiciona borda transparente */
  border-radius: 5px; /* Adiciona bordas arredondadas */
}

nav ul li a:hover {
  background-color: rgba(26, 16, 16, 0.919); /* Muda a cor de fundo ao passar
  o mouse */
  color: #faf9f9;
}

nav ul li a.active {
  background-color: #3fa1aa; /* Muda a cor de fundo quando clicado */
  color: #000; /* Muda a cor do texto quando clicado */
}

.footer-button {
  display: inline-block; /* Altera a exibição para inline-block */
  background-color: #007bff; /* Cor de fundo */
  color: #fff; /* Cor do texto */
  text-decoration: none;
  border-radius: 5px;
  justify-content: center;
}

.footer-button:hover {
  background-color: #0056b3; /* Cor de fundo ao passar o mouse */
}


@media (max-width: 768px) {
  .footer-button {
    flex-direction: row; /* Empilha os elementos verticalmente */
    align-items: center; /* Centraliza os itens horizontalmente */
    display: flex;
  }

  .button-container {
    text-align: center; /* Alinha os botões no centro horizontalmente */
  }
}
